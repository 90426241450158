import { useStableProps } from '@/hooks/useStable';
import StandaloneRelatedArticleSlider from 'base/components/RelatedArticleSlider';

export const HantStandaloneRelatedArticleSlider: typeof StandaloneRelatedArticleSlider = (props) => {
  const stableProps = useStableProps(
    {
      headline: 'Läs artiklar om ämnet',
      options: {
        $arrow: {
          className: '-mt-0.5',
        },
      },
    },
    props,
  );

  return <StandaloneRelatedArticleSlider {...stableProps} />;
};

export default HantStandaloneRelatedArticleSlider;
