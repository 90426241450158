import { tw } from '@/utils/tw';
import BaseImage from 'base/components/standalone/Image/theme';

const Image = tw.theme({
  extend: BaseImage,
  slots: {
    base: ['rounded-lg'],
    group: ['gap-y-3'],
    captionGroup: ['text-body-xs'],
    caption: ['text-body-xs', 'text-gray-950'],
    byline: ['text-input-sm', 'text-gray-600'],
  },
});

export default Image;
